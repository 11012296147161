// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-characters-js": () => import("./../../../src/pages/characters.js" /* webpackChunkName: "component---src-pages-characters-js" */),
  "component---src-pages-discussions-js": () => import("./../../../src/pages/discussions.js" /* webpackChunkName: "component---src-pages-discussions-js" */),
  "component---src-pages-goals-js": () => import("./../../../src/pages/goals.js" /* webpackChunkName: "component---src-pages-goals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-therapists-js": () => import("./../../../src/pages/therapists.js" /* webpackChunkName: "component---src-pages-therapists-js" */),
  "component---src-templates-entries-js": () => import("./../../../src/templates/entries.js" /* webpackChunkName: "component---src-templates-entries-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

